import { Method, request, Url } from '@/services/base';

async function getCertification() {
    return await request(Url.doctor.getCertification, Method.get);
}

async function certificate({
    name,
    sex,
    birthday,
    education,
    professionalTitle,
    goodAt,
    company,
    certImg,
}) {
    return await request(Url.doctor.certificate, Method.post, {
        name,
        sex,
        birthday,
        education,
        professionalTitle,
        goodAt,
        company,
        certImg,
    });
}

async function getPatients() {
    return await request(Url.doctor.getPatients, Method.get);
}

async function getHasDevicePatients({ year, month }) {
    return await request(Url.doctor.getHasDevicePatients, Method.get, { year, month });
}

export { getCertification, certificate, getPatients, getHasDevicePatients };
